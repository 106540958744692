<template>
  <div>
    <h3 class="title-g">
      用户管理
      <div class="searchbox-g">
        <el-select v-model="value" placeholder="请选择" size="mini">
          <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </el-select>
        <div class="list-g">
          <el-input type="text" class="inputbox-g" v-model="searchValue" size="mini"></el-input>
          <el-button type="primary" @click="search" size="mini">搜索</el-button>
        </div>
      </div>
    </h3>

    <el-tabs @tab-click="getUser" v-model="activeName">
      <el-tab-pane name="user">
        <span slot="label">
          注册用户
          <i>({{ count.user }} )</i>
        </span>
      </el-tab-pane>
      <el-tab-pane name="tour">
        <span slot="label">
          游客
          <i>({{ count.tour }} )</i>
        </span>
      </el-tab-pane>
      <el-tab-pane name="blackuser">
        <span slot="label">
          黑名单
          <i>({{ count.blackuser }} )</i>
        </span>
      </el-tab-pane>
      <el-tab-pane name="search" label="搜索结果" v-if="searchTab" disabled></el-tab-pane>
    </el-tabs>
    <table v-if="activeName=='user'|activeName=='blackuser'">
      <thead>
        <tr>
          <td>ID</td>
          <td>idUser</td>
          <td>vip</td>
          <td>手机号</td>
          <td>昵称</td>
          <td>城市</td>
          <td>次数</td>
          <td>上次登陆</td>
          <td>创建</td>
          <td>选择</td>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(item,i) in users" :key="i">
          <td>{{item.id}}</td>
          <td>{{item.idUser}}</td>
          <td :style="{color:(item.vip>0?'mediumseagreen':'red')}">{{item.vip}}</td>
          <td>{{item.regphoneUser}}</td>
          <td>{{item.nickName}}</td>
          <td>{{item.city}}</td>
          <td>{{item.logCounts}}</td>
          <td>{{item.lastLogTimeShort}}</td>
          <td>{{item.genTimeShort}}</td>
          <td>
            <el-button type="primary" size="mini" plain @click="getDetail(item)">查看</el-button>
          </td>
        </tr>
      </tbody>
    </table>
    <table v-else-if="activeName=='tour'">
      <thead>
        <tr>
          <td v-for="(value, key) in tableList" :key="key">{{ value }}</td>
          <td>查看</td>
        </tr>
      </thead>
      <tr v-for="(item, index) in users" :key="index">
        <td v-for="(value, key) in tableList" :key="key">
          <span v-if="key == 'avatarUrl'">
            <img :src="item[key]" height="30px" alt />
          </span>
          <span v-else>{{ item[key] }}</span>
        </td>
        <td>
          <el-button type="primary" size="mini" plain @click="openTourUser(item)">查看</el-button>
        </td>
      </tr>
    </table>
    <div v-else-if="search">
      <div v-if="!searchResult" style="text-align:center;padding-top:20vh">无结果</div>
      <el-descriptions
        :title="searchResult.nickName"
        :column="3"
        border
        style="margin: 40px 20px;"
        v-else
      >
        <el-descriptions-item v-for="(value, key) in tourHead" :key="key" :label="value">
          <div v-if="key === 'avatarUrl'">
            <img :src="searchResult[key]" alt height="40px" />
          </div>
          <div v-else>{{ searchResult[key] }}</div>
        </el-descriptions-item>
      </el-descriptions>
    </div>
    <br />
    <el-pagination
      :page-size="30"
      :pager-count="9"
      :current-page.sync="pageNumber"
      layout="total, prev, pager, next"
      :total="count[activeName]"
      hide-on-single-page
      @current-change="handleCurrentChange"
    ></el-pagination>

    <el-dialog :visible.sync="tourShow" destroy-on-close closeable width="80%" top="2vh">
      <el-descriptions :title="userSelected.nickName" :column="3" border style="margin: 40px 20px;">
        <el-descriptions-item v-for="(value, key) in tourHead" :key="key" :label="value">
          <div v-if="key === 'avatarUrl'">
            <img :src="userSelected[key]" alt height="40px" />
          </div>
          <div v-else>{{ userSelected[key] }}</div>
        </el-descriptions-item>
      </el-descriptions>
    </el-dialog>
  </div>
</template>

<script>
// import search from "../components/Search";
import { getAllUserAdmin } from "@/api/api";
export default {
  data() {
    return {
      activeName: "user",
      pageNumber: 1,
      tourShow: false,
      userSelected: {},
      searchTab: false,
      searchResult: null,
      tableList: {
        id: "SUBID",
        nickName: "昵称",
        avatarUrl: "头像",
        is_subscribe: "关注Z",
        district: "区县",
        city: "城市",
        lastLogTime: "上次登陆时间",
        logCounts: "次数",
        genTime: "创建时间"
      },
      tourHead: {
        id: "SUBID",
        idUser: "IDUSER",
        regphoneUser: "电话",
        nickName: "昵称",
        avatarUrl: "头像",
        adcode: "区码",
        district: "区县",
        city: "城市",
        province: "省",
        lastLogTime: "上次登陆时间",
        logCounts: "次数",
        genTime: "创建时间",
        is_subscribe: "关注十万八",
        unionid: "微信UID",
        miniOpenid: "微信ID",
        wechatOpenid: "微信公众号"
      },

      options: [
        {
          value: "regphoneuser",
          label: "手机号"
        },
        {
          value: "idUser",
          label: "用户ID"
        }
      ],
      value: "regphoneuser", //option的值
      searchValue: "",

      count: {
        user: 0,
        tour: 0,
        blackuser: 0
      },
      users: []
    };
  },
  mounted() {
    this.getAllUserFn();
  },
  methods: {
    getAllUserFn() {
      const that = this;
      getAllUserAdmin({
        state: that.activeName,
        page: that.pageNumber,
        type: that.value,
        value: that.searchValue,
        limit: 30
      }).then(res => {
        if (res.result) {
          let users = res.data.list;

          users.forEach(element => {
            if (element.idUser) {
              element.genTimeShort = String(element.genTime).slice(0, 10);
              element.lastLogTimeShort = String(element.lastLogTime).slice(
                0,
                10
              );
              element.vip = Math.max(
                (
                  (new Date(element.vipdate).getTime() - new Date().getTime()) /
                  (24 * 60 * 60 * 1000)
                ).toFixed(1),
                0
              );
            }
          });
          that.users = res.data.list;
          that.count = res.data.count;
        }
      });
    },
    getDetail(item) {
      this.$router.push({
        path: "/users/userdetail",
        query: { idUser: item.idUser }
      });
    },
    handleCurrentChange(val) {
      this.pageNumber = val;
      this.getAllUserFn();
    },
    getUser() {
      this.pageNumber = 1;
      this.users = [];
      this.searchTab = false;
      this.type = "";
      this.searchValue = "";
      this.searchResult = null;
      this.getAllUserFn();
    },
    search() {
      const that = this;
      this.pageNumber = 1;
      getAllUserAdmin({
        state: that.activeName,
        page: that.pageNumber,
        type: that.value,
        value: that.searchValue,
        limit: 30
      }).then(res => {
        if (res.result) {
          that.pageNumber = 1;
          this.activeName = "search";
          that.searchResult = res.data;
          that.searchTab = true;
        }
      });
    },
    openTourUser(item) {
      this.userSelected = item;
      this.tourShow = true;
    }
  }
};
</script>

<style scoped>
i {
  color: #999;
}
</style>